import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormBuilder, FormControl } from '@angular/forms';
import moment from 'moment-timezone';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { finalize, startWith, takeUntil } from 'rxjs/operators';
import { SiteService } from 'src/app/shared/services/site.service';

@Component({
  selector: 'app-date-v2',
  templateUrl: './date-v2.component.html',
  styleUrls: ['./date-v2.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class DateV2Component implements OnInit, OnDestroy {
  // Inputs
  @Input() controlName!: string;
  @Input() placeholder: string;
  @Input() yearRange: string;
  @Input() autoPopulate: boolean;
  @Input() showCalendar: boolean;
  @Input() showDate: boolean;
  @Input() showTime: boolean;
  @Input() required: boolean;
  @Input() showIcon: boolean;
  @Input() readonly: boolean;

  // Dom related
  @ViewChild('overlayPanel') overlayPanelElement!: OverlayPanel;

  externalControl!: FormControl;

  // Internal state
  DATE_FORMAT: string;
  TIME_FORMAT: string;
  DATE_TIME_FORMAT: string;

  minYear?: number;
  maxYear?: number;

  amOrPmOptions: { value: string; name: string }[];
  hourOptions: { value: string; name: string }[];
  monthOptions: { value: string; name: string }[];
  yearOptions: { value: string; name: string }[];

  initialized: boolean;

  internalDate!: Date;
  displayDate: string = '';

  // Subjects
  unsubscribe$ = new Subject();

  constructor(
    private siteService: SiteService,
    private controlContainer: ControlContainer,
    private fb: FormBuilder
  ) {
    this.DATE_FORMAT = 'DD/MM/YYYY';
    this.TIME_FORMAT = 'HH:mm';
    this.DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

    this.placeholder = 'Select Date / Time';
    this.yearRange = '1900:2030';
    this.autoPopulate = false;
    this.showCalendar = true;
    this.showDate = true;
    this.showTime = true;
    this.required = false;
    this.showIcon = false;
    this.readonly = false;

    this.amOrPmOptions = [
      { value: 'AM', name: 'AM' },
      { value: 'PM', name: 'PM' },
    ];

    this.hourOptions = [
      { value: '1', name: '1' },
      { value: '2', name: '2' },
      { value: '3', name: '3' },
      { value: '4', name: '4' },
      { value: '5', name: '5' },
      { value: '6', name: '6' },
      { value: '7', name: '7' },
      { value: '8', name: '8' },
      { value: '9', name: '9' },
      { value: '10', name: '10' },
      { value: '11', name: '11' },
      { value: '12', name: '12' },
    ];

    this.monthOptions = [
      { value: '1', name: 'January' },
      { value: '2', name: 'February' },
      { value: '3', name: 'March' },
      { value: '4', name: 'April' },
      { value: '5', name: 'May' },
      { value: '6', name: 'June' },
      { value: '7', name: 'July' },
      { value: '8', name: 'August' },
      { value: '9', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' },
    ];

    this.yearOptions = [];

    this.initialized = false;
  }

  ngOnInit(): void {
    // Validate inputs
    if (!(this.showDate || this.showCalendar) && !this.showTime) {
      throw new Error('date-v2: showDate or showTime must be true!');
    }

    // Initialize
    this.populateYearOptions();
    this.initExternalControl();
    this.handleAutoPopulate();

    this.initialized = true;
  }

  private initExternalControl() {
    const externalControl = this.controlContainer.control?.get(
      this.controlName
    ) as FormControl;

    this.externalControl = externalControl;

    this.siteService.addSubscriptionLog(
      this,
      'date-v2.component.ts->initExternalControl->this.externalControl.valueChanges'
    );

    this.externalControl.valueChanges
      .pipe(
        startWith(this.externalControl.value),
        finalize(() =>
          this.siteService.setSubscriptionLogFinalised(
            'date-v2.component.ts->initExternalControl->this.externalControl.valueChanges'
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((date) => {
        if (moment(date).isValid()) {
          this.displayDate = moment(date).format(this.getFormat());
          this.internalDate = date;
        } else {
          this.displayDate = '';
          this.internalDate = moment().startOf('day').toDate();
        }
      });
  }

  private populateYearOptions() {
    const years = this.yearRange.split(':');
    const startYear = +years[0];
    const endYear = +years[1];

    const yearOptions: { name: string; value: string }[] = [];

    for (let year = startYear; year <= endYear; year++) {
      yearOptions.push({
        name: year.toString(),
        value: year.toString(),
      });
    }

    this.yearOptions = yearOptions;
  }

  private handleAutoPopulate() {
    if (this.autoPopulate && !this.externalControl.value) {
      this.externalControl.patchValue(moment().startOf('day').toDate());
    }
  }

  // Calendar change the date and always set the time to 0:00
  // So we just need to pick the date and keep the time as it is
  onCalendarDateChange(event: Date) {
    this.internalDate = moment(this.internalDate)
      .set('date', event.getDate())
      .set('month', event.getMonth())
      .set('year', event.getFullYear())
      .toDate();
  }

  onDateChange(unit: any, value: number) {
    this.internalDate = moment(this.internalDate).set(unit, value).toDate();
  }

  onAmPmChange(amOrPm: any) {
    this.internalDate = moment(this.internalDate)
      .add(amOrPm === 'PM' ? +12 : -12, 'hour')
      .toDate();
  }

  onOk() {
    if (!moment(this.internalDate).isValid()) {
      return;
    }

    // If date only. Always set to start of that day
    if ((this.showDate || this.showCalendar) && !this.showTime) {
      this.internalDate = moment(this.internalDate).startOf('day').toDate();
    }

    this.externalControl.patchValue(this.internalDate);
    this.overlayPanelElement.hide();
  }

  onCancel() {
    this.overlayPanelElement.hide();
  }

  onClear() {
    this.externalControl.patchValue(null);
    this.overlayPanelElement.hide();
  }

  private getFormat() {
    if (this.showDate && this.showTime) {
      return this.DATE_TIME_FORMAT;
    }

    if (this.showDate) {
      return this.DATE_FORMAT;
    }

    if (this.showTime) {
      return this.TIME_FORMAT;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
