import { NgModule } from '@angular/core';
import { IgnoreNullPipe } from './ignore-null.pipe';
import { ArrangementPipe } from './arrangement.pipe';
import { ServiceProviderPipe } from './service-provider.pipe';
import { ServiceProviderByTagPipe } from './service-provider-by-tag.pipe';
import { ServiceProviderByServiceTypePipe } from './service-provider-by-service-type.pipe';
import { ServiceProviderServiceByServiceTypePipe } from './service-provider-service-by-service-type.pipe';
import { SortPipe } from './sort.pipe';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './html-safe.pipe';
import { EventTransferPipe } from './event-transfer.pipe';
import { DisposalTransferPipe } from './disposal-transfer.pipe';
import { PreparationTransferPipe } from './preparation-transfer.pipe';
import { DocumentPipe } from './document.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterContactsPipe } from './filter-contacts.pipe';
import { FilterDocumentsPipe } from './filter-documents.pipe';
import { ArrangementRegionPipe } from './arrangement-region.pipe';
import { ArrangementStatusPipe } from './arrangement-status.pipe';
import { ArrangementOrderPipe } from './arrangement-order.pipe';
import { ArrangementOwnerPipe } from './arrangement-owner.pipe';
import { TaskPipe } from './task.pipe';
import { TaskRemovedPipe } from './task-removed.pipe';
import { IsNotDeletedPipe } from './is-not-deleted.pipe';
import { AddressToStringPipe } from './address-to-string.pipe';

const pipeArray = [
  IgnoreNullPipe,
  ArrangementPipe,
  ServiceProviderPipe,
  ServiceProviderByTagPipe,
  ServiceProviderByServiceTypePipe,
  ServiceProviderServiceByServiceTypePipe,
  SortPipe,
  FilterPipe,
  SafePipe,
  EventTransferPipe,
  DisposalTransferPipe,
  PreparationTransferPipe,
  DocumentPipe,
  FileSizePipe,
  FilterContactsPipe,
  FilterDocumentsPipe,
  ArrangementRegionPipe,
  ArrangementStatusPipe,
  ArrangementOrderPipe,
  ArrangementOwnerPipe,
  TaskPipe,
  TaskRemovedPipe,
  IsNotDeletedPipe,
  AddressToStringPipe,
];

@NgModule({
  declarations: pipeArray,
  exports: pipeArray,
})
export class PipeModule { }
