<ng-container *ngIf="noArrangement !== true && hideContactSelector === false" [formGroup]="existingContactsForm">

  <div class="flex" #contactDropDownWrapper>

    <ng-container *ngIf="hideContactSelector === false">
  
      <p-dropdown *ngIf="(contacts$ | async) as c" #contactDropDown (onChange)="onChangeContact($event)" formControlName="contact" [options]="c | sort:'asc':'name'" optionLabel="name" filterBy="name" dataKey="id" class="contact-selector flex-auto w-1" [placeholder]="inputPlaceholder" appendTo="body">
        
        <ng-template pTemplate="empty">
          <span (click)="onAddNewContact($event)" class="block w-full">Add New Contact</span>
        </ng-template>
        
        <ng-template *ngIf="c.length" pTemplate="footer">
          <div class="px-3 py-2 border-top-1 border-right-none border-bottom-none border-left-none border-solid border-300"><span (click)="onAddNewContact($event)" class="block w-full">Add New Contact</span></div>
        </ng-template>
        
        <ng-template let-item pTemplate="selectedItem">
          <div *ngIf="contactSelected" class="flex align-items-center options-item options-item-value" [class.unnamed]="contactSelected.name === 'Unnamed Contact'">{{ contactSelected.name }}{{ (contactSelected.relationships.length) ? ' - ' : '' }}{{ contactSelected.relationships.join(', ') }}</div>
          <!-- <div class="flex align-items-center options-item options-item-value" [class.unnamed]="item.name === 'Unnamed Contact'">{{ item.id }} | {{ item.name }}{{ (item.relationships.length) ? ' - ' : '' }}{{ item.relationships.join(', ') }}</div> -->
        </ng-template>
        
        <ng-template let-option pTemplate="item">
          <div class="flex align-items-center options-item" [class.unnamed]="option.name === 'Unnamed Contact'">
            <div>{{ option.name }}{{ (option.relationships.length) ? ' - ' : '' }}{{ option.relationships.join(', ') }}</div>
          </div>
        </ng-template>
  
      </p-dropdown>
      
      <p-button styleClass="p-button-danger" class="contact-remove flex-none" (click)="onRemove($event, contactSelected)" [disabled]="!contactSelected" icon="pi pi-trash"></p-button>
      <p-button *ngIf="showContactVisibility" class="contact-visibility flex-none" (click)="isVisible = !isVisible" [disabled]="!contactSelected" [icon]="(isVisible && contactSelected) ? 'pi pi-eye' : 'pi pi-eye-slash'"></p-button>

    </ng-container>

  </div>

</ng-container>

<ng-container *ngIf="isReady" [formGroup]="formGroup">

  <div [class.hidden]="!isVisible">

    <div class="formgrid grid w-full" [style.display]="(noArrangement || contactSelected || hideContactSelector) ? 'flex' : 'none'">

      <div class="col-12 flex align-items-center" [appIsVisible]="options[8].state" visibleCssClass="is-hidden-display-flex">
        <h3 [appIsRequired]="'displayName'" class="flex-grow-1 text-500 border-bottom-1 border-200">Display Name</h3>
      </div>

      <div [appIsVisible]="options[8].state" class="field col-12">
        <input pInputText formControlName="displayName" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': formRoot.get('isSubmitted')?.value && formGroup.get('displayName')?.invalid }" />
      </div>

      <div class="col-12 flex align-items-center" [appIsVisible]="options[0].state" visibleCssClass="is-hidden-display-flex">
        <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Name</h3>
        <ng-container *ngIf="nameOptions">
          <p-menu #nameOptionsMenu [popup]="true" [model]="nameOptions" appendTo="body"></p-menu>
          <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(nameOptionsMenu).toggle($event)"></button>
        </ng-container>
      </div>

      <div class="col-12" [appIsVisible]="options[0].state">

        <app-name #name class="w-full" controlName="name" [states]="nameState"></app-name>

      </div>

      <div class="col-6" [appIsVisible]="options[1].state" visibleCssClass="is-visible-display-flex">

        <div class="formgrid grid w-full">

          <div class="col-12 flex align-items-center">
            <h3 [appIsRequired]="'gender'" class="flex-grow-1 text-500 border-bottom-1 border-200">Gender</h3>
          </div>

          <div class="col-12">
            <app-select id="gender" controlName="gender" [options]="genders | sort:'asc':'name'" [filter]="false" [showClear]="false" [showOptions]="false" placeHolder="Select Gender" class="inputfield w-full"></app-select>
          </div>

        </div>

      </div>

      <div class="col-6" [appIsVisible]="options[2].state" visibleCssClass="is-visible-display-flex">

        <div class="formgrid grid w-full">

          <div class="col-12 flex align-items-center">
            <h3 [appIsRequired]="'dateOfBirth'" class="flex-grow-1 text-500 border-bottom-1 border-200">Date of Birth</h3>
          </div>

          <div class="col-12">
            <app-date-v2 id="firstCall_date" controlName="dateOfBirth" [autoPopulate]="false" [required]="false" [showTime]="false" class="inputfield w-full"></app-date-v2>
          </div>

        </div>

      </div>

      <div class="col-6" [appIsVisible]="options[9].state" visibleCssClass="is-visible-display-flex">

        <div class="formgrid grid w-full">

          <div class="col-12 flex align-items-center">
            <h3 [appIsRequired]="'lifeStatus'" class="flex-grow-1 text-500 border-bottom-1 border-200">Life Status</h3>
          </div>

          <div class="col-12">
            <app-select id="lifeStatus" controlName="lifeStatus" [options]="lifeStatus | sort:'asc':'name'" [filter]="false" [showClear]="false" [showOptions]="false" placeHolder="Select Life Status" class="inputfield w-full"></app-select>
          </div>

        </div>

      </div>

      <div class="w-full" [appIsVisible]="options[3].state">

        <div class="col-12">

          <app-address-with-heading 
            headingText="Address" 
            addressControlName="address"
            internationalAddressToggleSwitchText="Use international address?"
          ></app-address-with-heading>

        </div>

        <!-- <div class="col-12 flex align-items-center">
          <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Address</h3>
          <ng-container *ngIf="addressOptions">
            <p-menu #addressOptionsMenu [popup]="true" [model]="addressOptions" appendTo="body"></p-menu>
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(addressOptionsMenu).toggle($event)"></button>
          </ng-container>
        </div>

        <div class="col-12">

          <app-address #address class="w-full" controlName="address" [states]="addressState"></app-address>

        </div> -->

      </div>
      
      <div class="w-full" [appIsVisible]="options[4].state">

        <div class="col-12">

          <app-address-with-heading 
            headingText="Mailing Address" 
            addressControlName="mailingAddress"
            internationalAddressToggleSwitchText="Use international address?"
            [hideControls]="formGroup.get('mailingAddressSameAsAddress')?.value"
          >

            <!-- mailingAddressSameAsAddress control switch -->
            <div class="col-12" additionalControl>

              <div class="flex align-items-center pr-3 mt-2 surface-ground border-round-lg border-1 border-200">
                <label class="flex-auto cursor-pointer p-3" (click)="formGroup.get('mailingAddressSameAsAddress')?.patchValue(!formGroup.get('mailingAddressSameAsAddress')?.value)">Use main address as mailing address?</label>
                <p-inputSwitch class="flex-initial" formControlName="mailingAddressSameAsAddress"></p-inputSwitch>
              </div>

            </div>

          </app-address-with-heading>

        </div>

<!-- 
        <div class="col-12 flex align-items-center">
          <h3 [appIsRequired]="'mailingAddress'" class="flex-grow-1 text-500 border-bottom-1 border-200">Mailing Address</h3>
          <ng-container *ngIf="addressOptions">
            <p-menu #mailingAddressOptionsMenu [popup]="true" [model]="mailingAddressOptions" appendTo="body"></p-menu>
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(mailingAddressOptionsMenu).toggle($event)"></button>
          </ng-container>
        </div>

        <div class="col-12">

          <app-address #mailingAddress class="w-full" controlName="mailingAddress" [states]="mailingAddressState"></app-address>

        </div> -->

      </div>
      
      <div class="col-12" formGroupName="phone" [appIsVisible]="options[5].state">

        <div class="grid">

          <div class="col-12 flex align-items-center">
            <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Phone</h3>
          </div>
    
          <div class="field col-6">
            <label [appIsRequired]="'primary'">Primary:</label>
            <input pInputText formControlName="primary" type="tel" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': formRoot.get('isSubmitted')?.value && formGroup.get('phone.primary')?.invalid }" />
          </div>
      
          <div class="field col-6">
            <label [appIsRequired]="'secondary'">Secondary:</label>
            <input pInputText formControlName="secondary" type="tel" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': formRoot.get('isSubmitted')?.value && formGroup.get('phone.secondary')?.invalid }" />
          </div>

        </div>

      </div>
      
      <div class="col-12" formGroupName="email" [appIsVisible]="options[6].state">

        <div class="grid">

          <div class="col-12 flex align-items-center">
            <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Email</h3>
          </div>

          <div class="field col-6">
            <label [appIsRequired]="'primary'">Primary:</label>
            <input pInputText formControlName="primary" type="email" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': formRoot.get('isSubmitted')?.value && formGroup.get('email.primary')?.invalid }" />
          </div>
      
          <div class="field col-6">
            <label [appIsRequired]="'secondary'">Secondary:</label>
            <input pInputText formControlName="secondary" type="email" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': formRoot.get('isSubmitted')?.value && formGroup.get('email.secondary')?.invalid }" />
          </div>

        </div>

      </div>
      
      <div class="w-full" [appIsVisible]="options[7].state">

        <div class="col-12 flex align-items-center">
          <h3 [appIsRequired]="'relationToDeceased'" (click)="onLogRelationship($event)" class="flex-grow-1 text-500 border-bottom-1 border-200">Relationship to Deceased</h3>
        </div>

        <div class="col-12" *ngIf="hideRelationToDeceased === false">
          
          <app-select-multi #relationshipSelectMulti (onChange)="onRelationToDeceasedChange($event)" class="w-full" controlName="relationToDeceased" [options]="relationsToDeceased | sort:'asc':'name'"></app-select-multi>
          
        </div>

      </div>
      
      <div class="w-full" [appIsVisible]="options[10].state">

        <div class="col-12 flex align-items-center">
          <h3 [appIsRequired]="'notes'" class="flex-grow-1 text-500 border-bottom-1 border-200">Notes</h3>
        </div>

        <div class="col-12">
          
          <textarea formControlName="notes" rows="5" pInputTextarea class="p-inputtext w-full"></textarea>
          
        </div>

      </div>

    </div>

  </div>

</ng-container>
