<p-overlayPanel #overlayPanel>
  <div *ngIf="initialized" class="flex gap-4">
    <div *ngIf="showCalendar">
      <p-calendar
        [inline]="true"
        [yearRange]="yearRange"
        [ngModel]="internalDate"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onCalendarDateChange($event)"
      >
      </p-calendar>
    </div>
    <div style="width: 350px">
      <div class="flex flex-column justify-content-between h-full">
        <div>
          <div *ngIf="showDate" class="flex gap-3 p-fluid">
            <div class="w-3">
              <label for="date">Date</label>
              <input
                autofocus
                type="number"
                placeholder="-"
                pInputText
                inputId="date"
                [min]="1"
                [max]="31"
                [ngModel]="internalDate | date : 'd'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onDateChange('date', +$event)"
              />
            </div>
            <div class="w-5">
              <label for="month">Month</label>

              <p-dropdown
                placeholder="-"
                class="mt-2"
                inputId="month"
                [options]="monthOptions"
                optionLabel="name"
                optionValue="value"
                [ngModel]="internalDate | date : 'L'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onDateChange('month', +$event - 1)"
              ></p-dropdown>
            </div>
            <div class="w-4">
              <label for="year">Year</label>
              <p-dropdown
                placeholder="-"
                class="mt-2"
                inputId="year"
                [options]="yearOptions"
                optionLabel="name"
                optionValue="value"
                [ngModel]="internalDate | date : 'y'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onDateChange('year', +$event)"
              ></p-dropdown>
            </div>
          </div>

          <div *ngIf="showTime" class="flex gap-3 p-fluid mt-3">
            <div class="w-3">
              <label for="hour">Hour</label>

              <p-dropdown
                placeholder="-"
                class="mt-2"
                inputId="hour"
                [options]="hourOptions"
                optionLabel="name"
                optionValue="value"
                [ngModel]="internalDate | date : 'h'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onDateChange('hour', +$event)"
              ></p-dropdown>
            </div>
            <div class="w-3">
              <label for="minute">Minute</label>

              <input
                type="number"
                placeholder="-"
                pInputText
                inputId="minute"
                [min]="0"
                [max]="59"
                [ngModel]="internalDate | date : 'm'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onDateChange('minute', +$event)"
              />
            </div>
            <div class="w-6 flex align-items-end w-1">
              <p-dropdown
                placeholder="-"
                class="flex-grow-1"
                placeholder="AM/PM"
                optionLabel="name"
                optionValue="value"
                [options]="amOrPmOptions"
                [ngModel]="internalDate | date : 'aa'"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onAmPmChange($event)"
              ></p-dropdown>
            </div>
          </div>
        </div>

        <div class="mt-3 flex p-fluid justify-content-between">
          <div>
            <p-button
              (onClick)="onClear()"
              styleClass="p-button-secondary"
              label="Clear"
            ></p-button>
          </div>
          <div class="flex gap-3 p-fluid">
            <p-button
              (onClick)="onCancel()"
              styleClass="p-button-secondary"
              label="Cancel"
            ></p-button>
            <p-button (onClick)="onOk()" label="OK"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div
  class="input-wrapper"
  (click)="overlayPanel.toggle($event)"
  [ngClass]="{ 'is-read-only': readonly ? true : false }"
>
  <input
    type="text"
    class="w-1"
    [placeholder]="placeholder"
    pInputText
    [readonly]="true"
    [(ngModel)]="displayDate"
    (focus)="overlayPanel.toggle($event)"
    [ngModelOptions]="{ standalone: true }"
  />

  <button
    class="btn-options"
    [disabled]="readonly"
    type="button"
    pButton
    icon="pi pi-calendar"
    (click)="overlayPanel.toggle($event)"
  ></button>
</div>
