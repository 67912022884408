<div class="p-inputgroup" [ngClass]="{ 'is-read-only': ((readonly) ? true : false) }">
  <p-dropdown #selectElement [formControlName]="controlName" [options]="options | ignoreNull" optionLabel="name" [readonly]="((readonly) ? true : false)" [filter]="filter" filterBy="name" [showClear]="showClear" [placeholder]="placeHolder" [dataKey]="dataKey" [ngClass]="{ 'ng-invalid ng-dirty' : formControl.invalid && isSubmittedControl?.value }" appendTo="body">
    <ng-template let-selectedOption pTemplate="selectedItem">
      <div class="flex align-items-center options-item options-item-value" *ngIf="selectedOption" (click)="showSelected($event)">
        <p-avatar *ngIf="selectedOption.thumb" [image]="selectedOption.thumb" class="mr-2" shape="circle"></p-avatar>
        <div *ngIf="titleCase">{{ selectedOption.name | titlecase }}</div>
        <div *ngIf="!titleCase">{{ selectedOption.name }}</div>
      </div>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <div class="flex align-items-center options-item">
        <p-avatar *ngIf="option.thumb" [image]="option.thumb" class="mr-2" shape="circle"></p-avatar>
        <div *ngIf="titleCase">{{ option.name | titlecase }}</div>
        <div *ngIf="!titleCase">{{ option.name }}</div>
      </div>
    </ng-template>
  </p-dropdown>
  <ng-container *ngIf="showOptions">
    <p-menu #optionsMenu [popup]="true" [model]="menuOptions" appendTo="body"></p-menu>
    <button type="button" pButton pRipple icon="pi pi-cog" (click)="optionsMenu.toggle($event)" [disabled]="((readonly) ? true : false) "></button> 
  </ng-container>
</div>