import { FormGroup } from "@angular/forms";
import { DynamicComponents } from "../dynamic/services/dynamic.service";
import { ServiceProvider } from "./service-provider";

import _, { has } from "lodash";
import { hasValidAddress } from "../has-valid-addres";

export interface serviceProductCategory {
  _meta: { index: number; id: string; deleted?: boolean; },
  name: string;
  value: string;
  disabled: boolean;
};

export interface serviceGroupedProducts { [key: string]: any[] };

export class Service {

  private serviceWrapper: FormGroup;
  private serviceProvider: ServiceProvider;

  form: FormGroup;

  get _meta(): { id: string, index: number } | null {
    return this.serviceWrapper.get('_meta')?.value;
  };

  get type(): DynamicComponents | null {
    return this.serviceWrapper.get('type')?.value;
  }

  get serviceProviderName(): string {
    return this.serviceProvider.name;
  }

  get serviceProviderAddress(): any {
    return this.serviceProvider.address;
  }

  get serviceProviderObj(): any {
    return this.serviceProvider.obj;
  }

  get name(): string {

    const serviceNameControl = this.form.get('name');

    return (serviceNameControl) ? serviceNameControl.value : this.serviceProvider.name;

  }

  get address(): any {

    const serviceAddressControl = this.form.get('defaultAddress');

    if (!serviceAddressControl) {

      return this.serviceProvider.address;

    } else {

      const hasServiceAddress = hasValidAddress(serviceAddressControl.value);

      if (hasServiceAddress) {

        let needsPatch = false;
        
        const address = serviceAddressControl.value;

        // Fix Address: Some (most) default addresses don't have their state set. We check if 
        // serviceAddressControl.value.street is set and serviceAddressControl.value.state is 
        // null. If so, we add the state from this.serviceProvider.address.state

        // Note: This is a temp fix to ensure that the address is correct when the service. The 
        // service provider should have the correct address set.
        if (hasServiceAddress && serviceAddressControl.value.street && !serviceAddressControl.value.state) {
          
          needsPatch = true;
          
        }

        if (needsPatch) {

          address.state = this.serviceProvider.address.state;

        }

        return address;

      } else {
          
        return this.serviceProvider.address;
  
      }

    }

  }

  get contact(): any {

    const serviceContactControl = this.form.get('contact');

    return (serviceContactControl) ? serviceContactControl.value : this.serviceProvider.contact;
    
  }

  get serviceProviderId(): number | null {
    return this.serviceProvider.id;
  }

  constructor(serviceProvider: ServiceProvider, serviceWrapper: FormGroup) {

    this.serviceProvider = serviceProvider;
    this.serviceWrapper = serviceWrapper;

    this.form = new FormGroup({});

  }

  public getProductCategories(): serviceProductCategory[] {

    const categories: any[] = _.get(this.form.value, 'productCategories');

    return categories;

  }

  public getGroupedProducts(): serviceGroupedProducts {

    const products: any[] = _.get(this.form.value, 'products');

    const grouped = _.groupBy(products, (product: any) => {

      return product.category.value;

    });

    return grouped;

  }

}
