import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, SkipSelf, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl } from '@angular/forms';
import _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';

interface onChangeEvent {
  originalEvent: PointerEvent,
  value: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class SelectComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() options!: any[];

  @Input() controlName!: string;
  @Input() placeHolder!: string;
  @Input() dataKey!: string;

  @Input() filter!: boolean;
  @Input() showClear!: boolean;
  @Input() showOptions!: boolean;
  @Input() titleCase!: boolean;
  @Input() asHTML!: boolean;
  @Input() disabled!: boolean | null;
  @Input() readonly!: boolean | null;

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('selectElement') selectElement!: Dropdown;

  formRoot!: AbstractControl;
  formControl!: FormControl;

  isInvalid: boolean;

  menuOptions: MenuItem[];

  subscriptions: Subscription[];
  
  get isSubmittedControl(): AbstractControl | null {
    return this.formRoot.get('isSubmitted');
  }

  constructor(
    private controlContainer: ControlContainer
  ) { 

    this.subscriptions = [];

    this.isInvalid = false;
    this.titleCase = true;
    this.asHTML = false;

    this.menuOptions = [
      {
        label: 'Add Option',
        command: () => { },
        icon: 'pi pi-plus'
      }
    ];

    this.showOptions = false;
    this.dataKey = '';

  }

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName) as FormControl;

    if (control) {

      this.formControl = control;
      this.formRoot = this.formControl.root;

      this.subscriptions.push(this.formControl.valueChanges.subscribe({
        next: (value) => {
          this.onSelect.emit(value);
        }
      }));

      if (this.formControl.value) {
        this.onSelect.emit(this.formControl.value);
      }

    }

    // Show filter if more than 5 options regardless of filter input
    if (this.options && this.options.length > 5) {

      this.filter = true;

    }

  }
  
  ngAfterViewInit(): void { }

  ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

  }

  showSelected(event: Event): void {
    // console.log('Control Value: ', this.formControl.value);
    // console.log('Options: ', this.options);
  }

  focus(): void {
    this.selectElement.focus();
  }
  
}
