import { FormArray, FormControl } from "@angular/forms";
import _ from "lodash";
import moment from "moment";
import ordinal from 'ordinal';
import { Arrangement } from "src/app/shared/classes/arrangement";
import { User } from "src/app/shared/classes/user";
import { FormGenerator } from "src/app/shared/forms/form-generator";
import { TaskStructure } from "src/app/shared/forms/form-structure";
import { taskStatusValue } from "src/app/shared/forms/form-values";
import { ArrangementStatus } from "src/app/shared/models/arrangement";
import { taskStatusType } from "../forms/form-enum";

export enum TaskAutoDueDateType {
    OneWeekFromTaskCreation = 'one-week-from-task-creation',
    DisposalDate = 'disposal-date',
    FuneralServiceDate = 'funeral-service-date',
    MemorialServiceDate = 'memorial-service-date',
    EventDate = 'event-date',
    TransferDate = 'transfer-date',
}

export interface TaskFuncResponse {
    add: boolean;
    remove: boolean;
    path?: string;
    index?: number;
    arrayId?: string;
}

export interface ArrangementTask {
    _meta: { id: string; index: number; };
    page: string;
    key: string;
    arrayId?: string;
    arrayPath?: string;
    autoDueDate?: boolean,
    autoDueDateOffset?: number,
    autoDueDateType?: TaskAutoDueDateType[],
    title: string;
    titlePrefix?: string;
    description: string;
    assignedTo: { roleId: number | null; userId: number | null; }
    func: (task: ArrangementTask, arrangement: Arrangement, user: User) => TaskFuncResponse[];
    // This will be assigned after arrangementTasks.process()
    status?: {value: taskStatusType, name: string}
}

const tasks: ArrangementTask[] = [
    { // Has active / pending death status
        _meta: { id: 'id-a', index: 0 },
        page: 'firstCall',
        key: 'call_place_of_death',
        autoDueDate: true,
        autoDueDateOffset: -1,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Call place of death and make sure required paper work is in place",
        description: "",
        assignedTo: { 
            roleId: 6,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {
            
            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let response = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasStatusActive = that.hasFormValue(arrangement, 'status', ArrangementStatus.Active, 'value');
            const hasStatusPendingDeath = that.hasFormValue(arrangement, 'status', ArrangementStatus.PendingDeath, 'value');

            if (hasStatusActive || hasStatusPendingDeath) {

                if (!hasTask) {

                    response.add = true;

                }

            } else {

                if (hasTask) {

                    response.remove = true;

                }

            }

            return [response];

        }
    }, { // Has `transfer.required` === true
        _meta: { id: 'id-b', index: 1 },
        page: 'firstCall',
        key: 'coordinate_transfer',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Co-ordinate transfer of the deceased from place of death",
        description: "<ul><li>It's a great idea to call the family and keep them up to date with the transfer of the deceased into our care.</li><li>Remember that when someone passes away, the family is usually exhausted and this can be a very stressful process.</li></ul>",
        assignedTo: { 
            roleId: 6,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let response = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasTransferRequired = that.hasFormValue(arrangement, 'transfer.required', true);
            
            if (hasTransferRequired) {

                if (!hasTask) {

                    response.add = true;

                }

            } else {

                if (hasTask) {

                    response.remove = true;

                }  

            }

            return [response];

        }
    }, { // Has active / pending death status
        _meta: { id: 'id-c', index: 2 },
        page: 'bookArrangementMeeting',
        key: 'schedule_planning_session',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Schedule planning session with the informant",
        description: "<ul><li>It's a good idea at this point to try and identify an informant who can help you collect information to complete the arrangement easily.</li><li>It's a good idea to find an informant who can help manage the concerns of the family effectively.</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasStatusActive = that.hasFormValue(arrangement, 'status', ArrangementStatus.Active, 'value');
            const hasStatusPendingDeath = that.hasFormValue(arrangement, 'status', ArrangementStatus.PendingDeath, 'value');

            if (hasStatusActive || hasStatusPendingDeath) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has active / pending death status
        _meta: { id: 'id-zl', index: 37 },
        page: 'bookArrangementMeeting',
        key: 'send_planning_meeting_email',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Send planning meeting email",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasStatusActive = that.hasFormValue(arrangement, 'status', ArrangementStatus.Active, 'value');
            const hasStatusPendingDeath = that.hasFormValue(arrangement, 'status', ArrangementStatus.PendingDeath, 'value');

            if (hasStatusActive || hasStatusPendingDeath) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // arrangement status !== empty
        _meta: { id: 'id-d', index: 3 },
        page: 'bookArrangementMeeting',
        key: 'remind_family_about_collection_for_mortuary',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Remind family about collection, labelling and delivery of clothing to mortuary",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Arrangement status !NULL
        _meta: { id: 'id-e', index: 4 },
        page: 'bookArrangementMeeting',
        key: 'remind_family_to_find_key_photo',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Remind family to find a key photo of the deceased and a start making a library of supporting photos for creating memorial items",
        description: "<ul><li>Getting a dropbox link setup to manage this can be helpful to streamline the sharing process</li><li>Conductor also contains a document library which can be used for media storage</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has `preparation.clothing.required` === true
        _meta: { id: 'id-f', index: 5 },
        page: 'preparationDetails',
        key: 'collect_label_and_deliver_clothing',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Collect, label and deliver clothing to mortuary",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasPreparationClothingRequired = that.hasFormValue(arrangement, 'preparation.clothing.required', true);

            if (hasPreparationClothingRequired) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }    

            return [isRequired];
            
        }
    }, { // Has `preparation.jewellery.required` === true
        _meta: { id: 'id-g', index: 6 },
        page: 'preparationDetails',
        key: 'complete_jewellery_instructions',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Complete jewellery instructions including signatory",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasPreparationJewelleryRequired = that.hasFormValue(arrangement, 'preparation.jewellery.required', true);

            if (hasPreparationJewelleryRequired) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }   

            return [isRequired];

        }
    }, { // Arrangement status !NULL
        _meta: { id: 'id-h', index: 7 },
        page: 'costSummary',
        key: 'share_cost_summary_with_family',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Share estimate with family and obtain sign off to proceed",
        description: "<ul><li>Upload the signed estimate into the document library for reference. </li><li>It's a good idea to remind the family of the requirement for a deposit to proceed.</li><li>It's a good idea to remind the family that we will only send invoices from Xero and that they should clarify any suspcious looking emails with you before making payment.</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }   

            return [isRequired];

        }
    }, { // Arrangement status !NULL
        _meta: { id: 'id-i', index: 8 },
        page: 'costSummary',
        key: 'send_invoice_to_family_for_payment',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Send financial invoice to family for payment of deposit",
        description: "",
        assignedTo: { 
            roleId: 7,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }  

            return [isRequired];

        }
    }, { // Notification array length > 0
        _meta: { id: 'id-j', index: 9 },
        page: 'newspaperNotifications',
        key: 'coordinate_newspaper_advertising',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Co-ordinate newspaper advertising",
        description: "<ul><li>It is important to check the publication dates and cut off dates for print advertising</li><li>It is useful to supply a generic template to the family containing a framework they can refine</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const newspaperNotificationValue: any[] = that.getFormValue(arrangement, 'newspaperNotifications');

            if (newspaperNotificationValue && newspaperNotificationValue.length) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }  

            return [isRequired];

        }
    }, { // Has active / pending death status
        _meta: { id: 'id-k', index: 10 },
        page: 'preparationDetails',
        key: 'send_preparation_instructions_to_mortuary',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Send preparation instructions to the Mortuary (send mortuary prep email/form - 2nd note)",
        description: "<ul><li>It is important to note any medial condition dependencies have been clarified for the disposition process</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const hasStatusActive = that.hasFormValue(arrangement, 'status', ArrangementStatus.Active, 'value');
            const hasStatusPendingDeath = that.hasFormValue(arrangement, 'status', ArrangementStatus.PendingDeath, 'value');

            if (hasStatusActive || hasStatusPendingDeath) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has viewing / washing event - event.type === 'dressing / washing' || 'viewing'
        _meta: { id: 'id-l', index: 11 },
        page: 'eventDetails',
        key: 'coordinate_viewing_or_washing',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate viewing/washing of the deceased",
        titlePrefix: "Event",
        description: "<ul><li>Always book an amount of time that will avoid rushing the family</li><li>It's a good idea to check the space you will conduct a viewing in and make sure the family will not receive any unwanted suprises</li><li>Calming music and a nice image of the deceased can be great additions to the space</li><li>Explaining what to expect to the family can really aid this process</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const eventType = _.get(event, 'type.value');
                    const meta = _.get(event, '_meta');

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (eventType === 'dressing-/-washing' || eventType === 'viewing') {

                        if (!hasTask) {

                            const obj: TaskFuncResponse = {
                                add: true,
                                remove: false,
                                index: index,
                            };

                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }

                            isRequired.push(obj);

                        } else {

                            isRequired.push({
                                add: false,
                                remove: false 
                            });

                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false,
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false,
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has First Call transfer
        _meta: { id: 'id-m', index: 12 },
        page: 'firstCall',
        key: 'first_call_coordinate_transfer_of_deceased',
        arrayPath: 'transfer.transfers',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.TransferDate
        ],
        title: "Co-ordinate transfer of deceased from the pick up location to the next required location",
        titlePrefix: "Transfer",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const transferValue: any[] = that.getFormValue(arrangement, 'transfer.transfers');

            if (Array.isArray(transferValue)) {

                let index = 0;

                for (const transfer of transferValue) {

                    const isFirstCall = _.get(transfer, 'isFirstCall');
                    const meta = _.get(transfer, '_meta');

                    const hasTask = that.hasTask(task, arrangement, transfer);

                    if (isFirstCall) {

                        if (!hasTask) {

                            const obj: TaskFuncResponse = {
                                add: true,
                                remove: false,
                                index: index,
                            };

                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }

                            isRequired.push(obj);

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false
                            });

                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Preparation Details transfer
        _meta: { id: 'id-n', index: 13 },
        page: 'preparationDetails',
        key: 'preparation_details_coordinate_transfer_of_deceased',
        arrayPath: 'transfer.transfers',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.TransferDate
        ],
        title: "Co-ordinate transfer of deceased from the pick up location to the next required location",
        titlePrefix: "Preparation Details",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const preparationTransferRequiredValue: any = that.getFormValue(arrangement, 'preparation.transfer.required');

            if (preparationTransferRequiredValue) {

                const transferValue: any[] = that.getFormValue(arrangement, 'transfer.transfers');

                if (Array.isArray(transferValue)) {

                    let index = 0;

                    for (const transfer of transferValue) {

                        const isPreparation = _.get(transfer, 'isPreparation');
                        const meta = _.get(transfer, '_meta');

                        const hasTask = that.hasTask(task, arrangement, transfer);

                        if (isPreparation) {

                            if (!hasTask) {
    
                                const obj: TaskFuncResponse = {
                                    add: true,
                                    remove: false,
                                    index: index,
                                };
    
                                if (_.has(meta, 'id')) {
                                    obj.arrayId = meta.id;
                                }
    
                                isRequired.push(obj);
    
                            } else {
    
                                isRequired.push({
                                    add: false, 
                                    remove: false 
                                });
    
                            }

                        }  else {

                            if (hasTask) {
    
                                isRequired.push({
                                    add: false, 
                                    remove: true 
                                });
    
                            } else {
    
                                isRequired.push({
                                    add: false, 
                                    remove: false 
                                });
    
                            }
                            
                        }

                        index++;

                    }

                }

            }

            return isRequired;

        }
    }, { // Has Event transfer - To
        _meta: { id: 'id-o', index: 14 },
        page: 'eventDetails',
        key: 'coordinate_transfer_of_deceased_to_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate transfer of deceased to the Event",
        titlePrefix: "Event",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const transferToRequired = _.get(event, 'transferTo.required');
                    const meta = _.get(event, '_meta');

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (transferToRequired) {

                        if (!hasTask) {

                            const obj: TaskFuncResponse = {
                                add: true,
                                remove: false,
                                index: index,
                            };

                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }

                            isRequired.push(obj);

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false
                            });

                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event transfer - From
        _meta: { id: 'id-o', index: 14 },
        page: 'eventDetails',
        key: 'coordinate_transfer_of_deceased_from_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate transfer of deceased from the Event",
        titlePrefix: "Event",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const transferToRequired = _.get(event, 'transferFrom.required');
                    const meta = _.get(event, '_meta');

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (transferToRequired) {

                        if (!hasTask) {

                            const obj: TaskFuncResponse = {
                                add: true, 
                                remove: false,
                                index: index,
                            };

                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }

                            isRequired.push(obj);

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false,
                            });

                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Book event venue
        _meta: { id: 'id-p', index: 15 },
        page: 'eventDetails',
        key: 'book_event_venue',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate availability and booking of the venue for the event",
        titlePrefix: "Event",
        description: "<ul><li>We always make a double booking for our ceremony events to avoid rushing the family</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const hasTask = that.hasTask(task, arrangement, event);
                    
                    if (!hasTask) {
                        
                        const meta = _.get(event, '_meta');

                        const obj: TaskFuncResponse = {
                            add: true, 
                            remove: false,
                            index: index,
                        };

                        if (_.has(meta, 'id')) {
                            obj.arrayId = meta.id;
                        }

                        isRequired.push(obj);

                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Book event celebrant / clergy
        _meta: { id: 'id-q', index: 16 },
        page: 'eventDetails',
        key: 'book_event_celebrant_or_clergy',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate and book celebrant/clergy for the event",
        titlePrefix: "Event",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const hasTask = that.hasTask(task, arrangement, event);
                    
                    if (!hasTask) {
                        
                        const meta = _.get(event, '_meta');

                        const obj: TaskFuncResponse = {
                            add: true, remove: false,
                            index: index,
                        };

                        if (_.has(meta, 'id')) {
                            obj.arrayId = meta.id;
                        }

                        isRequired.push(obj);

                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Organise Casket / coffin
        _meta: { id: 'id-r', index: 17 },
        page: 'eventDetails',
        key: 'coordinate_procurement_and_supply_of_coffin_or_casket',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate procurement and supply of the coffin/casket",
        titlePrefix: "Event",
        description: "<ul><li>It is important to clarify if an oversize coffin/casket will be required</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const hasTask = that.hasTask(task, arrangement, event);
                    
                    if (!hasTask) {
                        
                        const meta = _.get(event, '_meta');

                        const obj: TaskFuncResponse = {
                            add: true, remove: false,
                            index: index,
                        };

                        if (_.has(meta, 'id')) {
                            obj.arrayId = meta.id;
                        }

                        isRequired.push(obj);

                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Organise Staff for the Event
        _meta: { id: 'id-s', index: 18 },
        page: 'eventDetails',
        key: 'coordinate_staff_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate booking of staff for the event",
        titlePrefix: "Event",
        description: "<ul><li>The size of the venue, expected attendees and nature of the ceremony will dictate the number of staff required - this should be clarified</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const hasTask = that.hasTask(task, arrangement, event);
                    
                    if (!hasTask) {
                        
                        const meta = _.get(event, '_meta');

                        const obj: TaskFuncResponse = {
                            add: true, remove: false,
                            index: index,
                        };

                        if (_.has(meta, 'id')) {
                            obj.arrayId = meta.id;
                        }

                        isRequired.push(obj);

                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Event - Organise Music for the Event
        _meta: { id: 'id-t', index: 19 },
        page: 'eventDetails',
        key: 'coordinate_music_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate music playlist for the event (including audio)",
        titlePrefix: "Event",
        description: "<ul><li>Music tracks will need to be clarified and downloaded</li><li>Youtube can provide a really useful platform for sharing and agreeing on tracks</li><li>Audio tools should be clarified for the event</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'music') {

                                const lineItems = _.get(item, 'lineItems', []);

                                for (const lineItem of lineItems) {

                                    const lineItemCategory = _.get(lineItem, 'product.category.value');

                                    if (lineItemCategory === 'music') {

                                        taskIsRequired = true;

                                    }

                                }

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false,
                                remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Event - Organise Musician for the Event
        _meta: { id: 'id-u', index: 20 },
        page: 'eventDetails',
        key: 'coordinate_musicians_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate musicians for the event",
        titlePrefix: "Event",
        description: "<ul><li>Musicians will require playlists</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'music') {

                                const lineItems = _.get(item, 'lineItems', []);

                                for (const lineItem of lineItems) {

                                    const lineItemCategory = _.get(lineItem, 'product.category.value');

                                    if (lineItemCategory === 'musician') {

                                        taskIsRequired = true;

                                    }

                                }

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false, remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Event - Organise Slide Show for the Event
        _meta: { id: 'id-u', index: 20 },
        page: 'eventDetails',
        key: 'coordinate_slideshow_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate video slide-show for the event",
        titlePrefix: "Event",
        description: "<ul><li>Video slideshow playback should be tested</li><li>The venue facilities should be carefully considered and ideally tested prior</li><li>It is often advisable to take backup equipment or equipment you are well versed in</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'audio-visual') {

                                const lineItems = _.get(item, 'lineItems', []);

                                for (const lineItem of lineItems) {

                                    const lineItemCategory = _.get(lineItem, 'product.category.value');

                                    if (lineItemCategory === 'video-slide-show') {

                                        taskIsRequired = true;

                                    }

                                }

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false, remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Event - Organise Order of Service for the Event
        _meta: { id: 'id-v', index: 21 },
        page: 'eventDetails',
        key: 'coordinate_order_of_service_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate order of service for the event",
        titlePrefix: "Event",
        description: "<ul><li>The number of attendees should provide a guide to how many order of service booklets will be required</li><li>Ideally 2 days should be allowed for printing</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'stationary') {

                                const lineItems = _.get(item, 'lineItems', []);

                                for (const lineItem of lineItems) {

                                    const lineItemCategory = _.get(lineItem, 'product.category.value');

                                    if (lineItemCategory === 'order-of-service') {

                                        taskIsRequired = true;

                                    }

                                }

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false,
                                remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Event - Organise Memorial Signing Book for the Event
        _meta: { id: 'id-w', index: 22 },
        page: 'eventDetails',
        key: 'coordinate_memorial_signing_book_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Co-ordinate memorial signing book",
        titlePrefix: "Event",
        description: "<ul><li>It is a good idea to also provide a pen</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'stationary') {

                                const lineItems = _.get(item, 'lineItems', []);

                                for (const lineItem of lineItems) {

                                    const lineItemCategory = _.get(lineItem, 'product.category.value');

                                    if (lineItemCategory === 'memorial-signing-book') {

                                        taskIsRequired = true;

                                    }

                                }

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false,
                                remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Check Audio Visual equipment for the Event
        _meta: { id: 'id-x', index: 23 },
        page: 'eventDetails',
        key: 'check_audio_visual_equipment_for_event',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Check Audio Visual equipment",
        titlePrefix: "Event",
        description: "<ul><li>Conduct a thorough check of AV equipment prior to the event prior (ideally do this the morning of as well)</li><li>Bring spare batteries and clarify that all equipment has been charged for the event where required</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'audio-visual') {

                                taskIsRequired = true;

                            }

                        }

                    }

                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {
                        
                        if (!hasTask) {
                            
                            const meta = _.get(event, '_meta');
    
                            const obj: TaskFuncResponse = {
                                add: true, remove: false,
                                index: index,
                            };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }
    
                            isRequired.push(obj);
    
                        } else {
    
                            isRequired.push({
                                add: false, 
                                remove: false,
                            });
    
                        }

                    } else {

                        if (hasTask) {

                            isRequired.push({
                                add: false, 
                                remove: true 
                            });

                        } else {

                            isRequired.push({
                                add: false, 
                                remove: false 
                            });

                        }
                        
                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Has Event - Share Run sheet
        _meta: { id: 'id-y', index: 24 },
        page: 'eventDetails',
        key: 'share_run_sheet',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Share a run sheet with key stakeholders and brief the team",
        titlePrefix: "Event",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired: TaskFuncResponse[] = [];

            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {

                let index = 0;

                for (const event of eventsValue) {

                    const hasTask = that.hasTask(task, arrangement, event);
                    
                    if (!hasTask) {
                        
                        const meta = _.get(event, '_meta');

                        const obj: TaskFuncResponse = {
                            add: true, remove: false,
                            index: index,
                        };

                        if (_.has(meta, 'id')) {
                            obj.arrayId = meta.id;
                        }

                        isRequired.push(obj);

                    }

                    index++;

                }

            }

            return isRequired;

        }
    }, { // Preparation Details - Early Delivery
        _meta: { id: 'id-z', index: 25 },
        page: 'preparationDetails',
        key: 'preparation_details_early_delivery',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Co-ordinate early delivery with the mortuary and/or hearse provider",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = [{ add: false, remove: false }];
            let taskIsRequired = false;

            const lineItems: any[] = that.getFormValue(arrangement, 'preparation.lineItems');

            for (const lineItem of lineItems) {

                const lineItemCategory = _.get(lineItem, 'product.category.value');

                if (lineItemCategory === 'early-delivery') {

                    taskIsRequired = true;

                }

            }

            const hasTask = that.hasTask(task, arrangement, event);

            if (taskIsRequired) {
                
                if (!hasTask) {
                    
                    isRequired = [{
                        add: true,
                        remove: false,
                    }];

                } else {

                    isRequired.push({
                        add: false, 
                        remove: false 
                    });

                }

            } else {

                if (hasTask) {

                    isRequired.push({
                        add: false, 
                        remove: true 
                    });

                } else {

                    isRequired.push({
                        add: false, 
                        remove: false 
                    });

                }
                
            }

            return isRequired;

        }
    }, { // Has Disposal type - Coffin Size 
        _meta: { id: 'id-za', index: 26 },
        page: 'disposalDetails',
        key: 'coffin_dimensions',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Clarify coffin dimensions including handles",
        description: "<ul><li>This needs to be done for both burial and cremation</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const disposalTypeValue = that.getFormValue(arrangement, 'disposal.type');
            
            if (disposalTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has Disposal type - Book disposition venue 
        _meta: { id: 'id-zb', index: 27 },
        page: 'disposalDetails',
        key: 'book_disposition_venue',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Book disposition venue with required email or booking form",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const disposalTypeValue = that.getFormValue(arrangement, 'disposal.type');

            if (disposalTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has Disposal type - Check pace maker 
        _meta: { id: 'id-zc', index: 28 },
        page: 'disposalDetails',
        key: 'check_pace_maker',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Check pace maker / medical condition dependencies have been fulfilled",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const disposalTypeValue = that.getFormValue(arrangement, 'disposal.type');

            if (disposalTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has Disposal type - Co-ordinate collection of ashes, name plaque and crucifix 
        _meta: { id: 'id-zd', index: 29 },
        page: 'disposalDetails',
        key: 'coordinate_collection_of_ashes_name_plaque_and_crucifix',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Co-ordinate collection of ashes/name plaque and crucifix",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {


            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const disposalTypeValue = that.getFormValue(arrangement, 'disposal.type');

            if (disposalTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has Disposal type is Cremation - Stat Dec 
        _meta: { id: 'id-ze', index: 30 },
        page: 'disposalDetails',
        key: 'coffin_dimensions',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Disposal - Stat Dec - Coroner's, Attending Practice Cause of Death. Attending prac risk advice, medical referees cram permit, Sign and Submit NSW Health stat dec form, (4 bits of paper)",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const disposalTypeValue = that.getFormValue(arrangement, 'disposal.type.value');

            if (disposalTypeValue === 'cremation') {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has arrangement type - BDM
        _meta: { id: 'id-zf', index: 31 },
        page: 'bdn',
        key: 'submit_bdm',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Submit BDM form",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const arrangementTypeValue = that.getFormValue(arrangement, 'type');

            if (arrangementTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has arrangement type 
        _meta: { id: 'id-zg', index: 32 },
        page: 'costSummary',
        key: 'send_final_invoice',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Send final invoice to family for payment of total amount",
        description: "",
        assignedTo: { 
            roleId: 7,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            // Only continue if we DON'T have this task
            const hasTask = that.hasTask(task, arrangement);

            const arrangementTypeValue = that.getFormValue(arrangement, 'type');

            if (arrangementTypeValue) {
                
                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Event - Organise Floral Arrangement for Event
        _meta: { id: 'id-zh', index: 33 },
        page: 'eventDetails',
        key: 'organise_flowers',
        arrayPath: 'events',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.EventDate
        ],
        title: "Organise floral arrangement with flower provider",
        titlePrefix: "Event",
        description: "",
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            const that: ArrangementTasks = this as any;
            let isRequired: TaskFuncResponse[] = [];
            const eventsValue: any[] = that.getFormValue(arrangement, 'events');

            if (Array.isArray(eventsValue)) {
                let index = 0;

                for (const event of eventsValue) {

                    let taskIsRequired = false;

                    const items = _.get(event, 'items', []);

                    if (Array.isArray(items) && items.length) {

                        for (const item of items) {

                            const serviceType = _.get(item, 'serviceType.value');

                            if (serviceType === 'flower') {

                                taskIsRequired = true;

                            }
                            
                        }
                    }
                    const hasTask = that.hasTask(task, arrangement, event);

                    if (taskIsRequired) {

                        if (!hasTask) {

                            const meta = _.get(event, '_meta');
                            const obj: TaskFuncResponse = { add: true, remove: false, index: index, };
    
                            if (_.has(meta, 'id')) {
                                obj.arrayId = meta.id;
                            }

                            isRequired.push(obj);
                            
                        } else {
                            isRequired.push({ add: false, remove: false, });
                        }
                    } else {
                        if (hasTask) {
                            isRequired.push({ add: false, remove: true, });
                        } else {
                            isRequired.push({ add: false, remove: false, });
                        }   
                    }

                    index++;
                }
            }

            return isRequired;

        }
    }, { // Arrangement != NULL - Health Safety Assessment
        _meta: { id: 'id-zi', index: 34 },
        page: 'eventDetails',
        key: 'health_and_safety_assessment',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Complete Health and Safety Assessment",
        description: `Prior to the commencement of a funeral event, please consider the following for the safety of attendees: <ul><li>Hearse movement, coffin movement, amenities, pathways free from trip hazards, signage, waste management, infection control, weather conditions, equipment placement, occupancy limits, entrances and exits, personal safety, staff safety.</li></ul>`,
        assignedTo: { 
            roleId: 2,
            userId: null,
        },
        func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };
            const hasTask = that.hasTask(task, arrangement);
            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }

            return [isRequired];

        }
    }, { // Has arrangement != NULL - Cost Summary - Select a Charity for Donation
        _meta: { id: 'id-zj', index: 35 },
        page: 'costSummary',
        key: 'select_charity_for_donation',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Select a charity for donation",
        description: "<ul><li>Picaluna is a 'For-Profit' social enterprise and on behalf of every family we donate 5% of our profits to charity.</li></ul>",
        assignedTo: { 
            roleId: 2,
            userId: null,
        }, func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            const hasTask = that.hasTask(task, arrangement);
            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }   

            return [isRequired];

        }
    }, { // Has arrangement != NULL - Cost Summary - Make Donation Payment
        _meta: { id: 'id-zk', index: 36 },
        page: 'costSummary',
        key: 'make_charity_donation_payment',
        autoDueDate: true,
        autoDueDateOffset: 0,
        autoDueDateType: [
            TaskAutoDueDateType.DisposalDate, 
            TaskAutoDueDateType.FuneralServiceDate,
            TaskAutoDueDateType.MemorialServiceDate,
        ],
        title: "Make donation payment for selected charity",
        description: "",
        assignedTo: { 
            roleId: 7,
            userId: null,
        }, func: function(task: ArrangementTask, arrangement: Arrangement, user: User): TaskFuncResponse[] {

            // Typescript doesn't support type safety when calling `apply(this, [...arguments])` so we use this old-school solution
            const that: ArrangementTasks = this as any;

            let isRequired = { add: false, remove: false };

            const hasTask = that.hasTask(task, arrangement);
            const statusValue = that.getFormValue(arrangement, 'status');

            if (statusValue) {

                if (!hasTask) {

                    isRequired.add = true;

                }

            } else {

                if (hasTask) {

                    isRequired.remove = true;

                }

            }   

            return [isRequired];

        }
    }, 
];

export class ArrangementTasks {

    tasks: ArrangementTask[];

    constructor() {

        this.tasks = tasks;

    }

    process(arrangement: Arrangement, user: User) {

        // Add any new tasks
        for (const task of this.tasks) {

            const taskFuncResponse: TaskFuncResponse[] = task.func.apply(this, [task, arrangement, user]);

            if (Array.isArray(taskFuncResponse)) {

                const taskFuncResponseLength = taskFuncResponse.length;

                for (let i = 0; i < taskFuncResponseLength; i++) {                        
                    
                    const clonedTask = _.cloneDeep(task);

                    if (taskFuncResponse[i].arrayId) {
                        clonedTask.arrayId = taskFuncResponse[i].arrayId;
                    }

                    if (taskFuncResponse[i].add === true) {

                        this.addTask(i, clonedTask, arrangement, user, taskFuncResponse[i]);

                    } else {

                        if (taskFuncResponse[i].remove === true) {

                            this.removeTask(i, clonedTask, arrangement, user, taskFuncResponse[i]);

                        }

                    }

                }


            }

        }

        // Update task due dates based on Funeral Service / Disposal date 
        this.updateTaskDueDates(arrangement);

    }

    public hasRole(requiredRoleId: number | null, userRoleIds: number[] | undefined): boolean | null {
    
        if (!requiredRoleId || !Array.isArray(userRoleIds)) {
            return null;
        }
    
        const index = userRoleIds.findIndex(roleId => roleId === requiredRoleId);
    
        return (index > -1) ? true : false;
    
    };
    
    public hasRegion(requiredRegionId: number | null, userRegionIds: number[] | undefined): boolean | null {
        
        if (!requiredRegionId || !Array.isArray(userRegionIds)) {
            return null;
        }
    
        const index = userRegionIds.findIndex(regionId => regionId === requiredRegionId);
    
        return (index > -1) ? true : false;
    
    };

    public hasTask(sourceTask: ArrangementTask, arrangement: Arrangement, currentArrayItem?: any): boolean {

        const arrangementTasksFormArray = arrangement.form.get(`tasks.${sourceTask.page}`);

        if (arrangementTasksFormArray) {

            const arrangementTasksFormValue: any[] = arrangementTasksFormArray.value;

            if (Array.isArray(arrangementTasksFormValue) && arrangementTasksFormValue.length) {

                let taskFound = null;

                if (sourceTask.arrayPath) {

                    // Get the Tasks from the Arrangement that have the same key
                    const arrangementTasksWithMatchingKey: any[] = arrangementTasksFormValue.filter(task => _.get(task, 'key') === sourceTask.key && task.removed !== true);

                    if (Array.isArray(arrangementTasksWithMatchingKey) && arrangementTasksWithMatchingKey.length) {

                        // Now check if `currentArrayItem._meta.id` is the same as any of the tasks in `arrangementTasksWithMatchingKey`
                        const arrayItemWithIDFound = arrangementTasksWithMatchingKey.find(val => {

                            if (_.has(val, 'arrayId')) {

                                return _.get(val, 'arrayId') === _.get(currentArrayItem, '_meta.id');

                            } else {

                                return false;

                            }

                        });
    
                        if (arrayItemWithIDFound) {
    
                            taskFound = true;
    
                        }

                    }

                } else {

                    taskFound = arrangementTasksFormValue.find(arrangementTask => arrangementTask.key === sourceTask.key);

                }

                if (taskFound) {

                    return true;

                }

            }

        }

        return false;

    }

    public hasFormValue(arrangement: Arrangement, path: string, requiredValue: any, requiredValuePath: string | null = null): boolean {

        const formControl = arrangement.form.get(path);

        if (formControl) {

            let formControlValue = formControl.value;

            if (requiredValuePath) {

                formControlValue = _.get(formControlValue, requiredValuePath);

            }

            if (formControlValue === requiredValue) {

                return true;

            }

        }

        return false;

    }

    public getFormValue(arrangement: Arrangement, path: string, requiredValuePath: string | null = null): any | undefined {

        const formControl = arrangement.form.get(path);

        if (formControl) {

            let formControlValue = formControl.value;

            if (requiredValuePath) {

                formControlValue = _.get(formControlValue, requiredValuePath);

            }

            return formControlValue;

        }

        return undefined;

    }

    public addTask(index: number, task: ArrangementTask, arrangement: Arrangement, user: User, taskFuncResponse: TaskFuncResponse): void {

        const formGenerator = new FormGenerator();

        const dueDate = this.getTaskDueDate(arrangement, task);

        let title = task.title;

        if (task.titlePrefix) {

            const o = ordinal(index + 1);

            title = `${o} ${task.titlePrefix} - ${title}`; 

        }

        const formGroup = formGenerator.generate(TaskStructure, {
          createdAt: moment().toDate(),
          dueDate: dueDate,
          userId: user.data.id,
          autoDueDate: (task.autoDueDate) ? true : false,
          key: task.key,
          arrayId: (task.arrayId) ? task.arrayId : null,
          arrayPath: (task.arrayPath) ? task.arrayPath : null,
          title: title,
          description: task.description,
          assignedTo: {
            roleId: task.assignedTo.roleId,
            userId: task.assignedTo.userId,
          },
          status: taskStatusValue[0],
        });

        const targetFormArray = arrangement.form.get(`tasks.${task.page}`) as FormArray;
    
        if (targetFormArray) {

            FormGenerator.addToFormArray(targetFormArray, formGroup);

        }

    }

    public removeTask(index: number, task: ArrangementTask, arrangement: Arrangement, user: User, taskFuncResponse: TaskFuncResponse): void {

        const taskFormArray = arrangement.form.get(`tasks.${task.page}`) as FormArray;
        
        if (taskFormArray.length) {
            
            const taskFormArrayAsVale: any[] = taskFormArray.value;

            if (task.arrayPath) {

                for (let i = 0; i < taskFormArrayAsVale.length; i++) {

                    const key = _.get(taskFormArrayAsVale[i], 'key');
                    const removed = _.get(taskFormArrayAsVale[i], 'removed');
                    const arrayId = _.get(taskFormArrayAsVale[i], 'arrayId');

                    if (key === task.key && removed !== true && arrayId === taskFuncResponse.arrayId) {

                        taskFormArray.at(i).get('removed')?.patchValue(true);

                    }

                }

            } else {

                for (let i = 0; i < taskFormArrayAsVale.length; i++) {

                    const key = _.get(taskFormArrayAsVale[i], 'key');
                    const removed = _.get(taskFormArrayAsVale[i], 'removed');

                    if (key === task.key && removed !== true) {

                        taskFormArray.at(i).get('removed')?.patchValue(true);

                    }

                }
    
            }

        }

    }

    public removeEventTasks(arrangement: Arrangement, id: string): void {

        this.removeArrayTasks(arrangement, id, 'events');

    }

    public removeTransferTasks(arrangement: Arrangement, id: string): void {

        this.removeArrayTasks(arrangement, id, 'transfer.transfers');

    }

    private removeArrayTasks(arrangement: Arrangement, id: string, path: string): void {

        const arrangementAsValue = arrangement.form.value;

        const taskKeys = _.keys(arrangementAsValue.tasks);

        for (const taskKey of taskKeys) {

            const currentTaskFormArray = arrangement.form.get(`tasks.${taskKey}`) as FormArray;

            if (currentTaskFormArray && currentTaskFormArray.length) {

                const currentTasksAsValue: any[] = _.get(arrangementAsValue, `tasks.${taskKey}`);

                currentTasksAsValue.forEach((task, index) => {

                    const arrayPath = _.get(task, 'arrayPath');
                    const arrayId = _.get(task, 'arrayId');

                    if (arrayPath === path && arrayId === id) {
                        arrangement.form.get(`tasks.${taskKey}.${index}.removed`)?.patchValue(true);
                    }

                });

            }

        }

    }

    private getTaskDueDate(arrangement: Arrangement, task: ArrangementTask | undefined): Date | null {

        let dueDate: Date | null = null;

        // If we somehow don't have a valid `task` or `autoDueDateType` return null
        if (!task || !task.autoDueDateType || !Array.isArray(task.autoDueDateType)) {

            return dueDate;

        }

        for (const autoDueDateType of task.autoDueDateType) {

            if (autoDueDateType === TaskAutoDueDateType.DisposalDate) {

                const disposalDateFormControl = arrangement.form.get('disposal.date');
    
                if (disposalDateFormControl) {
        
                    const disposalDateFormControlValue = disposalDateFormControl.value;
        
                    if (disposalDateFormControlValue) {
        
                        dueDate = disposalDateFormControlValue;
        
                    }
        
                }

            } else if (autoDueDateType === TaskAutoDueDateType.EventDate) {

                if (task.arrayPath && task.arrayId) {

                    const events: any[] = _.get(arrangement.form.value, task.arrayPath);

                    const event: any = events.find(event => event._meta.id === task.arrayId);

                    if (event) {

                        dueDate = event.startDate;

                    }

                }

            } else if (autoDueDateType === TaskAutoDueDateType.TransferDate) {

                if (task.arrayPath && task.arrayId) {

                    const transfers: any[] = _.get(arrangement.form.value, task.arrayPath);

                    const transfer: any = transfers.find(transfer => transfer._meta.id === task.arrayId);

                    if (transfer) {

                        dueDate = transfer.date;

                    }

                }

            } else if (autoDueDateType === TaskAutoDueDateType.FuneralServiceDate) {

                const eventsFormArray = arrangement.form.get('events');

                if (eventsFormArray) {
    
                    const eventsFormArrayValue: any[] = eventsFormArray.value;
    
                    if (eventsFormArrayValue && Array.isArray(eventsFormArrayValue)) {
    
                        // Get the Funeral Service event type (if any)
                        const funeralServiceEvent = eventsFormArrayValue.find(event => {
                            
                            const eventTypeValue = _.get(event, 'type.value');
    
                            if (eventTypeValue === 'funeral-service') {
    
                                return true;
    
                            }
    
                            return false;
    
                        });
    
                        if (funeralServiceEvent) {
    
                            const funeralServiceStartDate = _.get(funeralServiceEvent, 'startDate');
    
                            if (funeralServiceStartDate) {
    
                                dueDate = funeralServiceStartDate;
    
                            }
    
                        }
    
                    }
    
                }

            } else if (autoDueDateType === TaskAutoDueDateType.MemorialServiceDate) {

                const eventsFormArray = arrangement.form.get('events');

                if (eventsFormArray) {
    
                    const eventsFormArrayValue: any[] = eventsFormArray.value;
    
                    if (eventsFormArrayValue && Array.isArray(eventsFormArrayValue)) {
    
                        // Get the Memorial Service event type (if any)
                        const memorialServiceEvent = eventsFormArrayValue.find(event => {
                            
                            const eventTypeValue = _.get(event, 'type.value');
    
                            if (eventTypeValue === 'memorial-service') {
    
                                return true;
    
                            }
    
                            return false;
    
                        });
    
                        if (memorialServiceEvent) {
    
                            const memorialServiceStartDate = _.get(memorialServiceEvent, 'startDate');
    
                            if (memorialServiceStartDate) {
    
                                dueDate = memorialServiceStartDate;
    
                            }
    
                        }
    
                    }
    
                }

            } else if (autoDueDateType === TaskAutoDueDateType.OneWeekFromTaskCreation) {

                dueDate = moment().add(7, 'days').toDate();

            }

            if (dueDate) {

                return dueDate;

            }

        }

        return null;

    }

    private updateTaskDueDates(arrangement: Arrangement): void {

        const arrangementTasksFormGroup = arrangement.form.get('tasks');

        if (arrangementTasksFormGroup) {

            const arrangementTasksValue = arrangementTasksFormGroup.value;

            if (arrangementTasksValue) {

                const keys = Object.keys(arrangementTasksValue);

                for (const key of keys) {

                    const tasks: any[] = arrangementTasksValue[key];

                    if (Array.isArray(tasks)) {

                        for (const index in tasks) {

                            const task = tasks[+index];

                            // Only predefined tasks have the `key` property
                            // We only update tasks that have a truthy value for `autoDueDate`
                            if (task && task.key && task.autoDueDate) {

                                // Get the pre-defined task (if the current task has the `key` property)
                                const preDefinedTask = this.tasks.find(t => t.key === task.key);
    
                                // Get the actual arrangement form control
                                const taskDueDateFormControl = arrangementTasksFormGroup.get(`${key}.${index}.dueDate`) as FormControl;
    
                                if (taskDueDateFormControl) {
    
                                    let newDueDate = this.getTaskDueDate(arrangement, preDefinedTask);
    
                                    if (newDueDate) {
    
                                        if (task.autoDueDateOffset < 0) {
    
                                            newDueDate = moment(newDueDate).subtract(task.autoDueDateOffset, 'days').toDate();
                                            
                                        } else if (task.autoDueDateOffset > 0) {
    
                                            newDueDate = moment(newDueDate).add(task.autoDueDateOffset, 'days').toDate();
    
                                        }
    
                                        taskDueDateFormControl.patchValue(newDueDate);
    
                                    }
    
                                }

                            }

                        }

                    }

                }

            }

        }

    }

}